import styled from '@emotion/styled';
import { createTheme, Grid, useMediaQuery } from '@mui/material';
import Heading from 'elements/Heading';
import { Sectional } from "layout/Sectional";
import React from 'react';
import { getDesignTokens } from 'AppGlobals';

export type TextListProperties = {
    title: string,
    links?: { title: string, link: string }[]
    elements?: string[],
    level?: number
};

const Name = styled.p<{ sm: boolean; }>`
    ${props => props.sm && `
        inline-size: 8em;
    `}
    overflow-wrap: break-word;
    margin: 0;
`;

const TextList: React.FC<TextListProperties> = ({ title, links, elements, level }) => {
    const headingLevel = level ?? 5;

    const isMediumBreakpoint = useMediaQuery('(max-width:900px)');
    const isSmallBreakpoint = useMediaQuery('(max-width:600px)');
    const headingWidth = isSmallBreakpoint  ? "4em" :
                         isMediumBreakpoint ? "6em" :
                         "8em";

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    // Update the theme only if the mode changes
    const theme = React.useMemo(() => createTheme(
      getDesignTokens(
        prefersDarkMode ? "dark" : "light")
      ),
      [
        prefersDarkMode ? "dark" : "light"
      ]
    );

    return (
        <Sectional
            title={""}
            sx={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}
        > 
            <Heading
                sx={{ width: headingWidth, marginRight: "1em"  }}
                level={headingLevel}
                sm={headingLevel + 1}
            >
                { title }
            </Heading>
            <Grid container>
                { links &&
                    links.map((e) => {
                        return (
                            <Grid item xs={6} sm={6} md={4} lg={3}>
                                <a href={ e.link }>{ e.title }</a>
                            </Grid>
                        )
                    })
                }
                { elements &&
                    elements.map((e) => {
                        return (
                            <Grid
                                item xs={6} sm={6} md={4} lg={3}
                                sx={{
                                    padding: ".5em"
                                }}
                            >
                                <Name
                                    sm={isSmallBreakpoint}
                                >
                                    { e }
                                </Name>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Sectional>
    )
};

export default TextList;