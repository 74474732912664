import { Alert, Container, Grid } from '@mui/material';
import { Header } from 'layout/Header';
import { QuoteSplash, QuoteSplashSubtitle, QuoteSplashTitle } from 'layout/QuoteSplash';
import { Quote, QuoteSubtitle, QuoteTitle } from 'layout/Quote';
import { Sectional } from 'layout/Sectional';
import React from 'react';
import * as blogData from '../build/blog.json';
import { MegaSplash, MegaSplashSubtitle, MegaSplashTitle, MegaSplashTitleSmall } from '../layout/MegaSplash';
import Splash, { ImageSplash, SplashContent, SplashTitle } from '../layout/Splash';
import { BlogPost, BlogWidget } from './Elements/Blog';
import { CTAWidget } from './Elements/CallToAction';
import { EventCalendarWidget } from './Elements/EventCalendar';
import { GREMADEWidget } from './Elements/GREMade';
import { MapWidget } from './Elements/GoogleMap';
import { MailingListWidget } from './Elements/MailingList';
import { MembershipDescriptionWidget } from './Elements/Memberships';
import { PodcastWidget } from './Elements/Podcast';
import { VolunteerFormWidget } from './Elements/Volunteer';
import { YoutubeWidget } from './Elements/Youtube';
import { CompletedExhibit, GeometryExhibit, MapExhibit, ResetExhibit, ShaderExhibit, StateMachineExhibit } from './Exhibits/Exhibits';
import { CollectionWidget } from './Home/Collection';
import { CommunityWidget } from './Home/Community';
import { PlayWidget } from './Home/Play';
import { SpaceWidget } from './Home/Space';
import { VisitWidget } from './Home/Visit';
import { PeopleWidget } from './Elements/People';
import { TimelineWidget } from './Elements/Timeline';
import { MetricsWidget } from './Elements/Metrics';
import { ExhibitsWidget } from './Elements/Exhibits';
import { InteractiveMapWidget } from './Elements/InteractiveMap';
import { ScavengerHuntWidget } from './ScavengerHunt/ScavengerHuntWidget';
import { GameJamWidget } from './Elements/GameJam';
import { PlexusPlayWidget } from './Elements/PlexusPlayWidget';
import { QRCodeGeneratorWidget } from './Elements/QRCodeGenerator';
import { TrackableGeneratorWidget } from './Elements/TrackableGenerator';
import { RentalWidget } from './Elements/Rental';
import TextList from './Elements/TextList';
import ImageList from './Elements/ImageList';
import Focus from './Elements/Focus';
import UnorderedList from './Elements/UnorderedList';
import { GoogleFormWidget } from './Elements/GoogleForm';

let imageSplashGenerator = (data: any, param: any) => {
  var widget = (
    <ImageSplash key={param.key} url={data.url}>
    </ImageSplash>
  );
  return widget;
}

let ctaGenerator = (data: any, param: any) => {
  var widget = (
    <CTAWidget
      key={param.key}
      title={data.title}
      button={data.button}
      url={data.url}
    />
  );
  return widget;
}

let splashGenerator = (data: any, param: any) => {
  return (
    <Sectional key={param.key} title={data.title} level={data.level} margin={data.margin}>
      <SplashContent>
        <Grid container>
          <Grid item xs={12} sm={data.columns ?? 6} dangerouslySetInnerHTML={{ "__html": data.content }}>
          </Grid>
        </Grid>
      </SplashContent>
    </Sectional>
  );
};

let paragraphGenerator = (data: any, param: any) => {
  return (
    <Sectional key={param.key} title={""} margin={data.margin}>
        <Grid container>
          <Grid item xs={12} sm={data.columns ?? 6} dangerouslySetInnerHTML={{ "__html": data.content }}>
          </Grid>
        </Grid>
    </Sectional>
  );
};

let splashRightGenerator = (data: any, param: any) => {
  return (
    <Sectional key={param.key} title={data.title}>
      <SplashContent>
        <Grid container>
          <Grid item xs={12} sm={6} dangerouslySetInnerHTML={{ "__html": data.content }}>
          </Grid>
          <Grid item xs={12} sm={6} >
            <img src={data.image} width="100%" />
          </Grid>
        </Grid>
      </SplashContent>
    </Sectional>
  );
};

let splashListGenerator = (data: any, param: any) => {
  return (
    <Sectional key={param.key} title={data.title}>
      <SplashContent>
        <Grid container>
          <Grid item xs={12} sm={8}>
            <ul>
              {
                data.items.map((item) => {
                  return (<li>
                    {item}
                  </li>)
                })
              }
            </ul>
          </Grid>
        </Grid>
      </SplashContent>
    </Sectional>
  );
};

let headerGenerator = (data: any, param: any) => {
  return (
    <Header key={param.key} title={data.title}>
    </Header>
  );
};

let megaSplashGenerator = (data: any, param: any) => {
  return (
    <MegaSplash key={param.key} smallImage={data.small_image} bigImage={data.big_image}>
      <MegaSplashTitle>{data.title}</MegaSplashTitle>
      <MegaSplashTitleSmall>{data.title_small}</MegaSplashTitleSmall>
      {
        data.subtitle === undefined ?
          <></> :
          <MegaSplashSubtitle>{data.subtitle}</MegaSplashSubtitle>
      }
    </MegaSplash>
  );
}

let quoteGenerator = (data: any, param: any) => {
  const subtitle = data.subtitle ? data.subtitle : "";
  return (
    <Quote key={param.key}>
      <QuoteTitle>{data.title}</QuoteTitle>
      {
        data.subtitle === undefined ?
          <></> : <QuoteSubtitle><div dangerouslySetInnerHTML={{ __html: subtitle }}></div></QuoteSubtitle>
      }
    </Quote>
  );
}

let quoteSplashGenerator = (data: any, param: any) => {
  const subtitle = data.subtitle ? data.subtitle : "";
  return (
    <QuoteSplash key={param.key} smallImage={data.small_image} bigImage={data.big_image}>
      <QuoteSplashTitle>{data.title}</QuoteSplashTitle>
      {
        data.subtitle === undefined ?
          <></> : <QuoteSplashSubtitle><div dangerouslySetInnerHTML={{ __html: subtitle }}></div></QuoteSplashSubtitle>
      }
    </QuoteSplash>
  );
}

let podcastGenerator = (data: any, param: any) => {
  return (
    <PodcastWidget key={param.key} url={data.url} />
  )
}

let eventCalendarGenerator = (data: any, param: any) => {
  return (
    <EventCalendarWidget key={param.key} url={data.url} />
  )
}

let volunteerFormGenerator = (data: any, param: any) => {
  return (
    <VolunteerFormWidget key={param.key} url={data.url} />
  )
}

let blogGenerator = (data: any, param: any) => {

  // if (param.post_id === undefined) {
  //     var last = blogData["posts"][blogData["posts"].length - 1];
  //     var previous = blogData.posts[blogData["posts"].length - 2];
  //     var blogItem:BlogPost = {
  //         date: last.date,
  //         title: last.title,
  //         content: last.content,
  //         previous: previous === undefined ? undefined : previous.date,
  //         next: undefined
  //     };
  //     return (
  //         <BlogWidget key={ param.key } data={ blogItem } />
  //     )
  // }

  // for (var p = 0; p < blogData.posts.length; p++) {
  //     if (blogData.posts[p].date === param.post_id) {
  //         var post = blogData.posts[p];
  //         var previous = blogData.posts[p - 1];
  //         var next = blogData.posts[p + 1];
  //         var blogItem:BlogPost = {
  //             date: post.date,
  //             title: post.title,
  //             content: post.content,
  //             previous: previous === undefined ? undefined : previous.date,
  //             next: next === undefined ? undefined : next.date
  //         };
  //         return (
  //             <BlogWidget key={param.key} data={ blogItem } />
  //         )
  //     }
  // }

  return (
    <Splash key={param.key}>
      Blog Is Currently Empty
    </Splash>
  )
}

let fallbackGenerator = (data: any, param: any) => {
  return (
    <Splash key={param.key}>
      <SplashTitle>
        Fallback
      </SplashTitle>
      <SplashContent>
        There was no widget with that name.
      </SplashContent>
    </Splash>
  );
}

let peopleGenerator = (data: any, param: any) => {

  return (
    <PeopleWidget key={param.key} people={data.people}>
    </PeopleWidget>
  )
}

let youtubeGenerator = (data: any, param: any) => {
  return (
    <YoutubeWidget component={data.component} key={param.key} subtitle={data.subtitle} title={data.title} height={data.height} url={data.url} />
  )
}

let gremadeGenerator = (data: any, param: any) => {
  return (
    <GREMADEWidget key={param.key} />
  )
}

let membershipGenerator = (data: any, param: any) => {
  return (
    <MembershipDescriptionWidget key={param.key} memberships={data.memberships} />
  )
}

let visitGenerator = (data: any, param: any) => {
  return (
    <VisitWidget key={param.key} data={data} />
  )
}

let mapGenerator = (data: any, param: any) => {
  return (
    <MapWidget key={param.key} data={data}>
    </MapWidget>
  )
}

let geometryExhibitGenerator = (data: any, param: any) => {
  return (
    <GeometryExhibit key={param.key} exhibitID={data.exhibitID} />
  )
}

let shaderExhibitGenerator = (data: any, param: any) => {
  return (
    <ShaderExhibit key={param.key} exhibitID={data.exhibitID} />
  )
}

let stateMachineExhibitGenerator = (data: any, param: any) => {
  return (
    <StateMachineExhibit key={param.key} exhibitID={data.exhibitID} />
  )
}

let mapExhibitGenerator = (data: any, param: any) => {
  return (
    <MapExhibit key={param.key} exhibitID={data.exhibitID} />
  )
}

let mailingListGenerator = (data: any, param: any) => {
  return (
    <MailingListWidget key={param.key} />
  )
}

let resetExhibitGenerator = (data: any, param: any) => {
  return (
    <ResetExhibit key={param.key} />
  )
}

let completedExhibitGenerator = (data: any, param: any) => {
  return (
    <CompletedExhibit key={param.key} />
  )
}

let collectionGenerator = (data: any, param: any) => {
  return (
    <CollectionWidget key={param.key} />
  )
}

let communityGenerator = (data: any, param: any) => {
  return (
    <CommunityWidget key={param.key} title={data.title} communityEvents={data.communityEvents} />
  )
}

let playGenerator = (data: any, param: any) => {
  return (
    <PlayWidget key={param.key} />
  )
}

let timelineGenerator = (data: any, param: any) => {
  return (
    <TimelineWidget key={param.key} events={data.events} />
  )
}

let spaceGenerator = (data: any, param: any) => {
  return (
    <SpaceWidget key={param.key} />
  )
}

let metricsGenerator = (data: any, param: any) => {
  return (
    <MetricsWidget key={param.key} title={data.title} image={data.image} description={data.description} fields={data.fields} />
  )
}

let educationGenerator = (data: any, param: any) => {
  return (
    <Sectional key={param.key} title="Learn">
    </Sectional>
  )
}

let exhibitsGenerator = (data: any, param: any) => {
  return (
    <ExhibitsWidget key={param.key} data={data} enableAudioTour />
  )
}

let interactiveMapGenerator = (data: any, param: any) => {
  return (
    <InteractiveMapWidget key={param.key} />
  )
}

const textListGenerator = (data: any, param: any) => {
  return (
    <TextList key={param.key} title={data.title} links={data.links} elements={data.elements} level={data.level} />
  )
};

const imageListGenerator = (data: any, param: any) => {
  return (
    <ImageList key={param.key} title={data.title} images={data.images} level={data.level} />
  )
};

const focusGenerator = (data: any, param: any) => {
  return (
    <Focus key={param.key} focus={data.focus} description={data.description} />
  )
};

const unorderedListGenerator = (data: any, param: any) => {
  return (
    <UnorderedList key={param.key} bullets={data.bullets} columns={data.columns} margin={data.margin} />
  )
};

let scavengerHuntGenerator = (data: any, param: any) => {
  return (
    <ScavengerHuntWidget key={param.key} exhibit_id={param.exhibit_id} />
  )
}

let gameJamGenerator = (data: any, param: any) => {
  return (
    <GameJamWidget key={param.key} />
  )
}

let plexusPlayGenerator = (data: any, param: any) => {
  return (
    <PlexusPlayWidget key={param.key} />
  )
}

let qrcodeGenerator = (data: any, param: any) => {
  return (
    <QRCodeGeneratorWidget key={param.key} />
  )
}

let trackableGenerator = (data: any, param: any) => {
  return (
    <TrackableGeneratorWidget key={param.key} />
  )
}

let rentalGenerator = (data: any, param: any) => {
  return (
    <RentalWidget key={param.key} data={data} />
  )
}

let googleFormGenerator = (data: any, param: any) => {
  return (
    <GoogleFormWidget key={param.key} url={data.url} formTitle={data.formTitle} iFramePxHeight={data.iFramePxHeight} customClassName={data.customClassName} />
  )
}

const widgets = {
  "megaSplash": megaSplashGenerator,
  "quoteSplash": quoteSplashGenerator,
  "quote":quoteGenerator,
  "header": headerGenerator,
  "splash": splashGenerator,
  "splashRight": splashRightGenerator,
  "splashList": splashListGenerator,
  "imageSplash": imageSplashGenerator,
  "cta": ctaGenerator,
  "podcast": podcastGenerator,
  "eventCalendar": eventCalendarGenerator,
  "fallback": fallbackGenerator,
  "map": mapGenerator,
  "volunteerForm": volunteerFormGenerator,
  "blog": blogGenerator,
  "people": peopleGenerator,
  "memberships": membershipGenerator,
  "youtube": youtubeGenerator,
  "gremade": gremadeGenerator,
  "visit": visitGenerator,
  "scavengerHunt": scavengerHuntGenerator,
  "geometryExhibit": geometryExhibitGenerator,
  "shaderExhibit": shaderExhibitGenerator,
  "stateMachineExhibit": stateMachineExhibitGenerator,
  "mapExhibit": mapExhibitGenerator,
  "resetExhibit": resetExhibitGenerator,
  "completedExhibit": completedExhibitGenerator,
  "mailingList": mailingListGenerator,
  "collection": collectionGenerator,
  "community": communityGenerator,
  "space": spaceGenerator,
  "education": educationGenerator,
  "play": playGenerator,
  "timeline": timelineGenerator,
  "metrics": metricsGenerator,
  "exhibits": exhibitsGenerator,
  "interactiveMap": interactiveMapGenerator,
  "gamejam": gameJamGenerator,
  "plexusplay": plexusPlayGenerator,
  "qrcode": qrcodeGenerator,
  "trackable": trackableGenerator,
  "rentals": rentalGenerator,
  "textList": textListGenerator,
  "imageList": imageListGenerator,
  "focus": focusGenerator,
  "unorderedList": unorderedListGenerator,
  "paragraph": paragraphGenerator,
  "googleForm": googleFormGenerator
};

let WidgetGenerator = (name: string) => {
  if (name in widgets) {
    return widgets[name];
  } else {
    return widgets["fallback"];
  }
}

export default WidgetGenerator;
