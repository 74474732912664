import React from "react";
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import { Sectional } from "layout/Sectional";
import Heading from "elements/Heading";

type CTAProperties = {
    title: string,
    button: string,
    url: string,
};

export const CTAWidget: React.FC<CTAProperties> = ({ title, button, url }) => {
    return (
        <>
            <Sectional title="" margin={0}>
                <Grid container sx={{ flexDirection: "column" }}>
                    <Heading level={4}>
                        {title}
                    </Heading>
                    <Button
                        variant="contained"
                        color="secondary"
                        href={url}
                        size="large"
                        sx={{
                            width: "fit-content",
                            marginTop: "1em",
                            marginBottom: "2em",
                        }}
                    >
                        {button}
                    </Button>
                </Grid>
            </Sectional>
        </>
    );
}