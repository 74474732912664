import React from "react"
import { Grid } from '@mui/material';
import { Sectional } from "layout/Sectional";

export type UnorderedListProperties = {
    bullets: string[],
    columns: number,
    margin?: number
}

export const UnorderedList: React.FC<UnorderedListProperties> = ({ bullets, columns, margin }) => {
    return (
        <Sectional title={""} margin={margin} >
            <ul>
                {bullets.map((item, idx) => (
                    (
                        <li key={ idx }>
                            <Grid
                                item
                                xs={12}
                                sm={columns}
                                dangerouslySetInnerHTML={{ "__html": item }}
                            />
                        </li>
                    )
                ))}
            </ul>
        </Sectional>
    )
}

export default UnorderedList;