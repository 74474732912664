import { Box, Container } from "@mui/material";
import React, { ReactNode } from "react";
import Heading from "elements/Heading";

type SectionalProperties = {
    title?: string | undefined,
    children?: ReactNode | undefined,
    level?: number,
    margin?: number,
    sx?: object
}

export const Sectional:React.FC<SectionalProperties> = ({
    title,
    children,
    level,
    margin,
    sx
}) => {
    return (
        <Box overflow="hidden" component="div" sx={ { 
            backgroundSize: 'cover', 
            backgroundPosition: 'left', 
            display: "block",
            marginBottom: `${margin ?? 50}px`
        }}> 
            <Container sx={ { 
                zIndex: "200",
                display: "block",
                ...sx
            } }>
                {title &&
                    <Box sx={ {
                        paddingTop: "30px"
                    } }>
                        <Heading level={level}>
                            { title }
                        </Heading>
                    </Box>
                }
                { children }
            </Container>
        </Box>
    );
}