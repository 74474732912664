import { Grid, Stack } from '@mui/material';
import { Sectional } from "layout/Sectional";
import React from 'react';

export type FocusProperties = {
    focus: string,
    description: string
};

const Focus: React.FC<FocusProperties> = ({ focus, description }) => {
    return (
        <Sectional title={""} level={4}>
            <Stack direction={"column"}>
                <strong>{focus}</strong>
                <Grid
                    sx={{ marginTop: "1em" }}
                    item
                    xs={12}
                    dangerouslySetInnerHTML={{ "__html": description }}
                />
            </Stack>
        </Sectional>
    );
}

export default Focus;