import { Typography, useMediaQuery } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { ReactNode } from "react";

export type HeadingProperties = {
    level?: number, // heading level: h1, h2 etc.
    className?: string,
    children?: ReactNode | undefined,
    sx?: object,
    lg?: number,
    md?: number,
    sm?: number,
};

const useLevelWithBreakpoints = (level: number | undefined, sm: number | undefined, md: number | undefined, lg: number | undefined) => {
    const isSmallBreakpoint = useMediaQuery('(max-width:600px)');
    const isMediumBreakpoint = useMediaQuery('(max-width:900px)');
    const isLargeBreakpoint = useMediaQuery('(min-width:900px)');

    if (isSmallBreakpoint && sm) {
        return sm;
    } else if (isMediumBreakpoint && md) {
        return md;
    } else if (isLargeBreakpoint && lg) {
        return lg;
    }

    return level;
};

const Heading: React.FC<HeadingProperties> =
    ({ level, className, children, sx, lg, md, sm }: HeadingProperties) => {
        const calcLevel = useLevelWithBreakpoints(level, sm, md, lg);
        const headingLevel: Variant = `h${calcLevel ? calcLevel : 2}` as Variant;

        return (
            <Typography
                sx={{ ...sx }}
                variant={headingLevel}
                className={className}
                gutterBottom
            >
                { children }
            </Typography>
        );
    }

export default Heading;