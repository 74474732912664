import { Box } from "@mui/material";
import React from "react";
import { GoogleFormProperties } from "widgets/Types/Types";

export const GoogleFormWidget: React.FC<GoogleFormProperties> = ({ url, formTitle, iFramePxHeight, customClassName }) => {
  return (
    <>
      <Box
        className={customClassName ? `${customClassName}` : ""} 
        // You can create a customClassName inside the @media query found in App.css under "Google Form - Narrow Screen Responsiveness". Follow the comment instructions found there. This is used for dynamically increasing the iFrame wrapper height as content is squished on narrower screens. This is done b/c "fit-content" does not work on google forms/cross-origin iFrames. Because of this, each unique form will need its own custom class, as each form will most likely vary in height.
        sx={{
          marginTop: "3em",
          width: "100%",
          height: `calc(${iFramePxHeight}px + 2em)`, // iFrame font-size does not scale with browser, 2em is to compensate for the rest of ui that does scale.
        }}
      >
        <iframe
          src={url}
          title={formTitle}
          width="100%"
          height="100%"
          frameBorder="none"
          scrolling="yes"
        >
          Loading…
        </iframe>
      </Box>
    </>
  );
};
