import { Box, Stack, useMediaQuery } from '@mui/material';
import Heading from 'elements/Heading';
import { Sectional } from "layout/Sectional";
import React from 'react';

export type ImageListProperties = {
    title: string,
    images: { height: string, image: string, link: string }[],
    level?: number
};

const ImageList: React.FC<ImageListProperties> = ({ title, images, level }) => {
    const headingLevel = level ?? 5;

    const isMediumBreakpoint = useMediaQuery('(max-width:900px)');
    const headingWidth = isMediumBreakpoint ? "4em" : "8em";

    return (
        <Sectional
            title={""}
            sx={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}
            level={level}
         >
            <Heading
                sx={{ width: headingWidth, marginRight: "1em" }}
                level={headingLevel}
                sm={headingLevel + 1}
            >
                { title }
            </Heading>
            <Stack
                alignItems={isMediumBreakpoint ? "start" : "center"}
                direction={isMediumBreakpoint ? "column" : "row"}
                justifyContent={"space-between"}
                spacing={3}
            >
                {
                    images.map((image) => {  
                        return (
                            <Box sx={{ maxWidth: "fit-content" }}>
                                <a href={image.link}>
                                    <Box
                                        sx={{
                                            "height": image.height,
                                            backgroundColor: "whitesmoke"
                                        }}
                                        component="img"
                                        src={image.image}
                                    />
                                </a>
                            </Box>
                        )
                    })
                }
            </Stack>
        </Sectional>
    );
};

export default ImageList;